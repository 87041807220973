import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import "../scss/pages/index.scss"
import EmailAddress from "../components/emailaddress"
import SocialIcons from "../components/socialicons"

const ContactPage = () => (
  <Layout template="subpage">
    <Seo
      title="Contact M's Mini Farm"
      description={`Want to reach out? We are happy to chat! Get in contact with M's Mini Farm. `}
    />

    <section className={`content-section bg-color-cream text-center`}>
      <div className={`container-lg inner`}>
        <h1>Contact</h1>
        <p className={`intro-statement`}>
            We are always happy to chat. Drop us a message at the following email
            address to get in contact.
        </p>
        <EmailAddress />
      </div>
    </section>

    <section className={`content-section bg-color-dark-slate text-center`}>
      <div className={`container-full inner`}>
        <h2>Social</h2>
        <p>You can always catch up with us on Social Media as well:</p>
        <SocialIcons />
      </div>
    </section>
  </Layout>
)

export default ContactPage
